var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{attrs:{"justify":"end"}},[_c('v-col',[_c('h2',[_vm._v("ສະຫຼຸບຍອດຕຸກນ້ຳຂອງສາຂາ")])])],1),_c('v-row',{staticClass:"mb-1 mt-0"},[_c('v-col',[_c('h3',{staticClass:"success--text"},[_vm._v(" ຕຸກນ້ຳຍັງເຫຼືອ: "+_vm._s(Intl.NumberFormat().format(_vm.sumTotalCurrentBottles))+" ")])])],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.branchBottles,"items-per-page":15,"loading":_vm.TableLoading,"disabled":_vm.TableLoading,"hide-default-footer":true,"loading-text":"Loading... Please wait"}}),_c('br'),_c('br'),_c('v-row',[_c('v-col',[_c('v-menu',{attrs:{"close-on-content-click":true,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"ເລີ່ມວັນທີ","readonly":"","outlined":"","dense":""},model:{value:(_vm.start_date),callback:function ($$v) {_vm.start_date=$$v},expression:"start_date"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.start_menu),callback:function ($$v) {_vm.start_menu=$$v},expression:"start_menu"}},[_c('v-date-picker',{model:{value:(_vm.start_date),callback:function ($$v) {_vm.start_date=$$v},expression:"start_date"}})],1)],1),_c('v-col',[_c('v-menu',{attrs:{"close-on-content-click":true,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"ຫາວັນທີ","readonly":"","outlined":"","dense":""},model:{value:(_vm.end_date),callback:function ($$v) {_vm.end_date=$$v},expression:"end_date"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.end_menu),callback:function ($$v) {_vm.end_menu=$$v},expression:"end_menu"}},[_c('v-date-picker',{model:{value:(_vm.end_date),callback:function ($$v) {_vm.end_date=$$v},expression:"end_date"}})],1)],1),_c('v-col',{staticClass:"d-flex"},[_c('v-autocomplete',{attrs:{"items":_vm.Types,"label":"ເລືອກປະເພດ","item-text":"name","item-value":"value","dense":"","outlined":""},model:{value:(_vm.selectedType),callback:function ($$v) {_vm.selectedType=$$v},expression:"selectedType"}})],1)],1),_c('v-row',{staticClass:"mb-1 mt-0"},[_c('v-col',[_c('h3',{staticClass:"info--text"},[_vm._v(" ຕຸກເປົ່າຍັງເຫຼືອ: "+_vm._s(Intl.NumberFormat().format(_vm.sumTotalEmptyCurrentBottles))+" ")])]),_c('v-col',[_c('h3',{staticClass:"info--text"},[_vm._v(" ຕຸກເປົ່າເລືອກຕາມວັນທີ: "+_vm._s(Intl.NumberFormat().format(_vm.sumTotalEmptyBottlesFiltered))+" ")])])],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headersEmptyBottle,"items":_vm.branchEmptyBottles,"loading":_vm.loadingEmpty,"disabled":_vm.loadingEmpty,"hide-default-footer":"","disable-pagination":true,"loading-text":"Loading... Please wait"},scopedSlots:_vm._u([{key:"item.product_type",fn:function(ref){
var item = ref.item;
return [(_vm.selectedType == 'import')?_c('div',[_vm._v(_vm._s(item.import_bottle_type))]):_c('div',[_vm._v(_vm._s(item.export_bottle_type))])]}},{key:"item.import_store_type",fn:function(ref){
var item = ref.item;
return [(item.import_store_type)?_c('div',[(_vm.selectedType == 'import')?_c('div',[_vm._v(_vm._s(item.import_store_type.name))]):_vm._e()]):_vm._e(),(item.export_store_type)?_c('div',[(_vm.selectedType == 'export')?_c('div',[_vm._v(_vm._s(item.export_store_type.license_plate))]):_vm._e()]):_vm._e()]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [(item.type)?_c('div',[(item.type == 'import')?_c('div',[_vm._v("ຮັບເອົາ")]):_vm._e(),(item.type == 'export')?_c('div',[_vm._v("ສົ່ງອອກ")]):_vm._e()]):_vm._e()]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.moment(item.created_at).format("DD-MM-YYYY HH:mm "))+" ")]}}])}),_c('br'),[(_vm.pagination.last_page > 1)?_c('Pagination',{attrs:{"pagination":_vm.pagination,"offset":_vm.offset},on:{"paginate":function($event){return _vm.fetchBranchEmptyBottle()}}}):_vm._e()]],2)}
var staticRenderFns = []

export { render, staticRenderFns }