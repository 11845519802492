<template>
  <div>
    <v-row justify="end">
      <v-col><h2>ສະຫຼຸບຍອດຕຸກນ້ຳຂອງສາຂາ</h2></v-col>
      <!--  <v-col class="al-end"> <v-btn color="primary">Export</v-btn></v-col> -->
    </v-row>
    <!-- <v-row class="my-2" justify="end">
      <v-col><h2>ທັງໝົດ {{}}</h2></v-col>
    </v-row> -->
    <!-- <hr /> -->

    <v-row class="mb-1 mt-0">
      <v-col>
        <h3 class="success--text">
          ຕຸກນ້ຳຍັງເຫຼືອ:
          {{ Intl.NumberFormat().format(sumTotalCurrentBottles) }}
        </h3>
      </v-col>
    </v-row>

    <!-- <hr /> -->
    <v-data-table
        :headers="headers"
        :items="branchBottles"
        :items-per-page="15"
        class="elevation-1"
        :loading="TableLoading"
        :disabled="TableLoading"
        :hide-default-footer="true"
        loading-text="Loading... Please wait"
    >
      <!--Action -->
    </v-data-table>
    <br/>
    <br/>
    <v-row>
      <v-col>
        <v-menu
            v-model="start_menu"
            :close-on-content-click="true"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="start_date"
                label="ເລີ່ມວັນທີ"
                readonly
                outlined
                v-bind="attrs"
                v-on="on"
                dense
            ></v-text-field>
          </template>
          <v-date-picker
              v-model="start_date"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col>
        <v-menu
            v-model="end_menu"
            :close-on-content-click="true"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="end_date"
                label="ຫາວັນທີ"
                readonly
                outlined
                v-bind="attrs"
                v-on="on"
                dense
            ></v-text-field>
          </template>
          <v-date-picker
              v-model="end_date"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col class="d-flex">
        <v-autocomplete
            v-model="selectedType"
            :items="Types"
            label="ເລືອກປະເພດ"
            item-text="name"
            item-value="value"
            dense
            outlined
        ></v-autocomplete>
      </v-col>
    </v-row>

    <!--    Bottle empty-->
    <v-row class="mb-1 mt-0">
      <v-col>
        <h3 class="info--text">
          ຕຸກເປົ່າຍັງເຫຼືອ:
          {{ Intl.NumberFormat().format(sumTotalEmptyCurrentBottles) }}
        </h3>
      </v-col>
      <v-col>
        <h3 class="info--text">
          ຕຸກເປົ່າເລືອກຕາມວັນທີ:
          {{ Intl.NumberFormat().format(sumTotalEmptyBottlesFiltered) }}
        </h3>
      </v-col>
    </v-row>

    <!-- <hr /> -->
    <v-data-table
        :headers="headersEmptyBottle"
        :items="branchEmptyBottles"
        class="elevation-1"
        :loading="loadingEmpty"
        :disabled="loadingEmpty"
        hide-default-footer
        :disable-pagination="true"
        loading-text="Loading... Please wait"
    >
      <!--Action -->
      <template v-slot:item.product_type="{ item }">
        <div v-if="selectedType == 'import'">{{ item.import_bottle_type}}</div>
        <div v-else>{{ item.export_bottle_type}}</div>
      </template>
      <template v-slot:item.import_store_type="{ item }">
        <div v-if="item.import_store_type">
          <div v-if="selectedType == 'import'">{{ item.import_store_type.name}}</div>
        </div>
        <div v-if="item.export_store_type">
          <div v-if="selectedType == 'export'">{{ item.export_store_type.license_plate}}</div>
        </div>
      </template>
      <template v-slot:item.type="{ item }">
        <div v-if="item.type">
          <div v-if="item.type == 'import'">ຮັບເອົາ</div>
          <div v-if="item.type == 'export'">ສົ່ງອອກ</div>
        </div>
      </template>
      <template v-slot:item.created_at="{ item }">
        {{ moment(item.created_at).format("DD-MM-YYYY HH:mm ") }}
      </template>
    </v-data-table>
    <br/>
    <template>
      <Pagination
          v-if="pagination.last_page > 1"
          :pagination="pagination"
          :offset="offset"
          @paginate="fetchBranchEmptyBottle()"
      ></Pagination>
    </template>
  </div>
</template>

<script>
import Pagination from "@/plugins/pagination/pagination";
export default {
  components: {
    Pagination,
  },
  metaInfo() {
    return {
      title: `ລາຍງານຍອດຕຸກນ້ຳສາຂາ`,
    }
  },
  data() {
    return {
      start_date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
      end_date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
      start_menu: false,
      end_menu: false,
      exportLoading: false,
      loading: false,
      TableLoading: false,
      loadingEmpty: false,
      viewModalBranch: false,
      viewModalBranchExport: false,
      branchBottles: [],
      branchEmptyBottles: [],

      selectedType: "import",
      Types: [
        {
          name: "ຮັບເອົາ",
          value: "import",
        },
        {
          name: "ສົ່ງອອກ",
          value: "export",
        },
      ],
      sumTotalCurrentBottles: 0,
      sumTotalEmptyCurrentBottles: 0,
      sumTotalEmptyBottlesFiltered: 0,

      branchId: "",
      //Pagination
      offset: 8,
      pagination: {},
      per_page: 25,
      headers: [
        {text: "ລະຫັດ", align: "center", value: "barcode"},
        {
          text: "ຜະລິດຕະພັນ",
          align: "start",
          sortable: false,
          value: "name",
        },
        {text: "ລວມຕຸກນ້ຳ", align: "center", value: "countBottles"},
        {text: "ລາຄາຊື້", align: "center", value: "purchase_price"},
      ],
      headersEmptyBottle: [
        {text: "ຜະລິດຕະພັນ", value: "product_type",    sortable: true,},
        {
          text: "ຈຳນວນ",
          align: "start",
          sortable: false,
          value: "amount",
        },{
          text: "ຮັບເອົາ/ສົ່ງອອກ",
          sortable: true,
          value: "type",
        },
        {text: "", value: "import_store_type"},
        {text: "ວັນທີ", value: "created_at"},
      ],
    };
  },
  methods: {
    fetchBranchBottle() {
      this.TableLoading = true;
      this.$axios
          .get("/branch/list-products",)
          .then((res) => {
            setTimeout(() => {
              this.branchBottles = res.data.listProducts;
              this.sumTotalCurrentBottles = res.data.totalAmount;
              this.start_menu = false;
              this.end_menu = false;
              this.TableLoading = false;
            }, 100);
          })
          .catch(() => {
            this.TableLoading = false;
            this.end_menu = false;
            this.start_menu = false;
            this.$store.commit("Toast_State", this.toast_error);
          });
    },
    fetchBranchEmptyBottle() {
      this.loadingEmpty = true;
      this.$axios
          .get("branch/report-empty-bottle-branch", {
            params: {
              startDate: this.start_date,
              endDate: this.end_date,
              type: this.selectedType,
              page: this.pagination.current_page,
              per_page: this.per_page,
            }
          })
          .then((res) => {
            this.branchEmptyBottles = res.data.branch_empty_bottles.data;
            console.log(this.branchEmptyBottles);
            this.sumTotalEmptyCurrentBottles = res.data.sumTotal_in_branch;
            this.sumTotalEmptyBottlesFiltered = res.data.total_bottle;
            this.pagination = res.data.branch_empty_bottles;
            this.start_menu = false;
            this.end_menu = false;
            this.loadingEmpty = false;
          })
          .catch(() => {
            this.loadingEmpty = false;
            this.end_menu = false;
            this.start_menu = false;
            this.$store.commit("Toast_State", this.toast_error);
          });
    },
  },
  watch: {
    selectedType: function () {
      this.fetchBranchEmptyBottle();
    }, start_date: function () {
      this.fetchBranchEmptyBottle();
    }, end_date: function () {
      this.fetchBranchEmptyBottle();
    },
  },
  created() {
    this.fetchBranchEmptyBottle();
    this.fetchBranchBottle();
  },
};
</script>

<style>
.al-end {
  text-align: end !important;
}

.invoice {
  font-size: 16px;
  color: #000000;
}

.primary-color {
  color: #00c1d2;
}
</style>
